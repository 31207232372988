import { OneWalletConnector } from '@harmony-react/onewallet-connector';
import { MathWalletConnector } from '@harmony-react/mathwallet-connector';
import { InjectedConnector } from '@web3-react/injected-connector';

import { AbstractConnector } from '@web3-react/abstract-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

export enum ConnectorNames {
	OneWallet = 'OneWallet',
	Mathwallet = 'MathWallet',
	Metamask = 'Metamask',
	WalletConnect = 'WalletConnect',
}

const onewallet = new OneWalletConnector({ chainId: 1 }); // Mainnet -> chainId: 1 // Testnet & Localnet -> chainId 2
const mathwallet = new MathWalletConnector({ chainId: 1 }); // Mainnet -> chainId: 1 // Testnet & Localnet -> chainId 2
export const injected = new InjectedConnector({ supportedChainIds: [1666600000, 1666700000] });
const walletconnect = new WalletConnectConnector({
	rpc: {
		1: 'https://api.s0.t.hmny.io/',
		1666600000: 'https://api.s0.t.hmny.io/',
		1666700000: 'https://api.s0.b.hmny.io'
	},
	bridge: 'https://bridge.walletconnect.org',
	qrcode: true,
	supportedChainIds: [
		1666600000, // harmony
		1666700000// harmony testnet
	],
	// qrcodeModalOptions: {
	//   mobileLinks: [
	//     "metamask",
	//     "math",
	//     "trust",
	//     "safepal",
	//     "guarda",
	//   ],
	// }
})

export const connectorsByName: { [connectorName: string]: AbstractConnector } = {
	[ConnectorNames.OneWallet]: onewallet,
	[ConnectorNames.Mathwallet]: mathwallet,
	[ConnectorNames.Metamask]: injected,
	[ConnectorNames.WalletConnect]: walletconnect,
};

export const connectorsByNameMobile: { [connectorName: string]: AbstractConnector } = {
	[ConnectorNames.WalletConnect]: walletconnect,
};
