import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  Stack,
  Grid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  Center,
  GridItem,
  Spacer,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons'
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import { fromWei, Units, Unit } from '@harmony-js/utils';
import {TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon} from "react-share";
import { Contract } from '@harmony-js/contract';
import { useCoingeckoPrice } from '@usedapp/coingecko'
import Get247InfoCardJSON from "./Get247InfoCardJSON"
import GetDetails from "./GetDetails"
import GetDetailsDonate from "./GetDetailsDonate"
import GetDetailsDonateSchool from "./GetDetailsDonateSchool"

import { useHarmony } from '../context/harmonyContext';

import { createParentContract, getParentContractFromConnector } from '../helpers/contractHelper';
import { createChildContract, getChildContractFromConnector } from '../helpers/contractHelper';
import GetDetailsMin from './GetDetailsMin';
import { ColumnLayoutIcon } from 'evergreen-ui';

const InfoContract = () => {
  const [campsStored, setCampsStored] = useState([]);
  const [campsStored_forDropdown, setCampsStored_forDropdown] = useState([]);
  const [modcampsStored, setmodCampsStored] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
	const { hmy, fetchBalance } = useHarmony();
	const [parentcontract, setParentContract] = useState<Contract | null>(createParentContract(hmy));
	const { account, connector, library } = useWeb3React();
  const [tabIndex, setTabIndex] = useState(0);
  const [state, setState] = useState({
    athletename: "",
    description: "",
    targetschool1: "",
    targetschool2: "",
    targetschool3: "",
    contract: "",
    value2add: "",
    school: "",
    athleteSelect: "",
    schoolSelect: "",
    athleteSelectRefund: "",
    schoolSelectRefund: "",
    tabIndex: 0,
    fetchIndex: 0
  })
  const [childcontract, setChildContract] = useState<Contract | null>(createChildContract(state.athleteSelect, hmy));
  // console.log('parent contract: ', parentcontract);
  const harmonyPrice = useCoingeckoPrice('harmony', 'usd');
  const donatevalUSD = (state.value2add !== '' && harmonyPrice) ? (parseFloat(state.value2add))*parseFloat(harmonyPrice) : 0.00;
  const da_array: any = [];
  let even_newer_da_array: any = [];
  // let newer_da_array2: any = [];
  // testing comment
  const url = new URL(window.location.href);
  const txkey = url.searchParams.get("txkey");
  // console.log('txkey:', txkey);
  // console.log('athleteselect:', state.athleteSelect);
  function getCampsStored(inputdata: any) {
		if (parentcontract) {
			try {
        const camps = inputdata;
				// console.log('campaigns: ', camps);
        //*****here is where you state the infinite scroll qty - initial set to 3*****
        state.fetchIndex = state.fetchIndex + 3;
        setCampsStored(camps.slice(0,state.fetchIndex));
        console.log('fetch index: ', state.fetchIndex);
        setIsFetching(false);
        // setCampsStored(camps);
        // console.log('camp list: ', campsStored);
        // console.log('camp list 3: ', campsStored.slice(0,3));
			} catch (error) {
				console.error(error);
			}
		}
	};
  const getCampsStored_forDropdown = async () => {
		if (parentcontract) {
			try {
				const camps_forDropdown = await parentcontract.methods.returnAllProjects().call();
				setCampsStored_forDropdown(camps_forDropdown);
			} catch (error) {
				console.error(error);
			}
		}
	};

  const handleTabsChange = (index: any) => {
    setTabIndex(index)
  }

  const handleFundBtn = (addr: any) => {
    setTabIndex(2);
    state.athleteSelect = addr;
  }

	function listOfProjects() {
		// console.log('campsStored: ', campsStored);
    if (campsStored) {
      const listOprojects: any = campsStored;
      // const listOprojects: any = even_newer_da_array;
      if (txkey) {
        listOprojects[0] = txkey;
      }
      const projList = listOprojects.map(function(projectAddress: string){
        return (
          <Grid
            templateRows='repeat(6, auto)'
            templateColumns='repeat(3, 1fr)'
            gap={2}
            borderRadius="3xl"
            border="1px"
            borderStyle="solid"
            borderColor="gray.500"
            bg="gray.600"
            minWidth="350px"
            >
              <GridItem
                rowSpan={1}
                colSpan={3}
                color="white"
                fontSize="4xl"
              >
                <Flex>
                  <Box mr="3" />
                  <TwitterShareButton
                    url={"https://nilfund.me?txkey=" + projectAddress}
                    hashtags={ ["nilfundme", "showmethemoney"] }
                  >
                    <TwitterIcon size={40} round />
                  </TwitterShareButton>
                  <FacebookShareButton
                    url={"https://nilfund.me?txkey=" + projectAddress}
                  >
                    <FacebookIcon size={40} round />
                  </FacebookShareButton>
                  <Spacer />
                  <Box mr="3">
                    <IconButton ml="10px" aria-label='fund!' variant='outline' colorScheme='green' onClick={() => handleFundBtn(projectAddress)} icon={<AddIcon />} />
                  </Box>
                </Flex>
              </GridItem>
              {/* <GetDetails key={projectAddress} address={projectAddress} sendDataToParent={sendDataToParent} /> */}
              <GetDetails key={projectAddress} address={projectAddress} />
            </Grid>
        )
      })
      if (txkey) {
        return projList[0];
      }
      else {
        return projList;
      }
    }
  }

  function listOfProjectsDonate_forDropdown() {
    if (campsStored_forDropdown) {
      const projList = campsStored_forDropdown.map(function(projectAddress: string){
        return <GetDetailsDonate key={projectAddress} address={projectAddress} sendDataToParent={sendDataToParent}/>;
      })
      return projList;
    }
  }

  function sortFunction(a: any, b: any) {
    if (a[1] === b[1]) {
        return 0;
    }
    else {
        return (parseInt(a[1]) > parseInt(b[1])) ? -1 : 1;
    }
  }
  // COMPLETE: sort the array 'da_array' by value highest to lowest, strip the 2nd column, and then delte duplicates!! 
  const sendDataToParent = async (index: any, value: any) => {
    if (index !== undefined && value !== undefined) {
      da_array.push([index, value]);
      da_array.sort(sortFunction);
      // console.log("da array: ", da_array);
      const new_da_array = da_array.map(function(val: any) {
        return val.slice(0, -1);
      });
      const newer_da_array = new_da_array.flat();
      even_newer_da_array = newer_da_array.filter(function(elem: any, index: any, self: any) {
        return index === self.indexOf(elem);
      })
      // console.log("even newer array: ", even_newer_da_array);
      if (even_newer_da_array.length == campsStored_forDropdown.length) {
        await delay(1000)
        setIsLoading(false);
      }
    }
  }

  function handleInputChange(evt: any) {
    const value = evt.target.value;
    // console.log('ath select: ', state.athleteSelect);
    setState({
      ...state,
      [evt.target.name]: value
    });
    // listOfProjects2();
    if (connector && state.athleteSelect) {
			(async () => {
				const childcontract = await getChildContractFromConnector(connector, library, state.athleteSelect);
				setChildContract(childcontract);
        // console.log('child contract: ', childcontract);
			})();
		}
    if (connector && state.athleteSelectRefund) {
			(async () => {
				const childcontract = await getChildContractFromConnector(connector, library, state.athleteSelectRefund);
				setChildContract(childcontract);
        // console.log('child contract: ', childcontract);
			})();
		}
  }

  const handleStartContract = async () => {
    if (parentcontract){
      if (state.athletename && state.description && state.targetschool1 && state.targetschool2 && state.targetschool3 && account) {
        try {
  				const newcontract = await parentcontract.methods.startProject(
              state.athletename,
              state.description,
              state.targetschool1,
              state.targetschool2,
              state.targetschool3
            ).send({
              from: account,
              gasPrice: 30000000000,
    					gasLimit: 3000000,
            });
            toast.success('Transaction done', {
    					onClose: async () => {
    						await getCampsStored(modcampsStored);
                await fetchBalance(account);
    					},
    				});
  			} catch (error) {
  				console.error(error);
  			};
      }
      else {
    			toast.error('Connect your wallet');
    	}
    }
  }

  const handleDonateContract = async () => {
    // console.log('ath select: ', state.athleteSelect);
    if (childcontract) {
      if (state.athleteSelect && state.schoolSelect && account) {
        try {
  				const fundcontract = await childcontract.methods.contribute(
              state.schoolSelect
            ).send({
              from: account,
              gasPrice: 30000000000,
              gasLimit: 90000,
              value: new Unit(state.value2add).asOne().toWei(),
            });
            toast.success('Transaction done', {
    					onClose: async () => {
    						await getCampsStored(modcampsStored);
                await fetchBalance(account);
    					},
    				});
  			} catch (error) {
  				console.error(error);
  			};
      }
      else {
    			toast.error('Connect your wallet');
    	}
    }
  }
// open to refund: 0xC3E9D612C1C7Ba33Dd2f76A37Ac66Fe64D011850 @ Louisville
  const handleRefundContract = async () => {
    // console.log('ath select: ', state.athleteSelect);
    if (childcontract) {
      if (state.athleteSelectRefund && state.schoolSelectRefund && account) {
        try {
  				const refundcontract = await childcontract.methods.getRefund(
              state.schoolSelectRefund
            ).send({
              from: account,
              gasPrice: 30000000000,
    					gasLimit: 90000,
            });
            toast.success('Transaction done', {
    					onClose: async () => {
    						await getCampsStored(modcampsStored);
                await fetchBalance(account);
    					},
    				});
  			} catch (error) {
  				console.error(error);
  			};
      }
      else {
    			toast.error('Connect your wallet');
    	}
    }
  }

  function handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
    setIsFetching(true);
    console.log('Fetch more list items!');
  }

  console.log("isLoading: ", isLoading);

	// useEffect(() => {
	// 	getCampsStored();
	// }, []);

  function delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  useEffect(() => {
		// setmodCampsStored(even_newer_da_array);
    getCampsStored_forDropdown();
    // listOfProjects();
    // getCampsStored();
	}, []);

  useEffect(() => {
    if (!isFetching) return;
    getCampsStored(even_newer_da_array);
  }, [isFetching]);

  useEffect(() => {
    if (isLoading) return;
		setmodCampsStored(even_newer_da_array);
    getCampsStored(even_newer_da_array);
	}, [isLoading]);

	useEffect(() => {
		if (connector) {
			(async () => {
				const parentcontract = await getParentContractFromConnector(connector, library);
				setParentContract(parentcontract);
        // console.log('parent contract: ', parentcontract);
			})();
		}
	}, [connector, setParentContract]);

  useEffect(() => {
		if (connector) {
			(async () => {
				const childcontract = await getChildContractFromConnector(connector, library, state.athleteSelect);
				setChildContract(childcontract);
        // console.log('child contract: ', childcontract);
			})();
		}
	}, [connector, setChildContract]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function handleDonateBux(evt: any) {
    let value = (harmonyPrice) ? (0.00/parseFloat(harmonyPrice)).toFixed(2) : 0.00;
    console.log("evt: ", evt.target.id);
    if (evt.target.id == "20bux") {
      value = (harmonyPrice) ? (20.00/parseFloat(harmonyPrice)).toFixed(2) : 0.00;
    }
    else if (evt.target.id == "50bux") {
      value = (harmonyPrice) ? (50.00/parseFloat(harmonyPrice)).toFixed(2) : 0.00;
    }
    else if (evt.target.id == "100bux") {
      value = (harmonyPrice) ? (100.00/parseFloat(harmonyPrice)).toFixed(2) : 0.00;
    }
    setState({
      ...state,
      value2add: value.toString()
    });
    // listOfProjects2();
  }
	return (
    <Flex
      direction="column"
      align="center"
      mt="4"
      border="1px"
      borderStyle="solid"
      borderColor="gray.700"
      borderRadius="xl"
      minWidth="375px"
      >
      <Box mt={2}>
      <Tabs
      isFitted
      color="white"
      variant="enclosed"
      colorScheme='green'
      index={tabIndex}
      onChange={handleTabsChange}
      >
        <Center>
          <TabList>
            <Tab>main</Tab>
            <Tab>new fund</Tab>
            <Tab>fund</Tab>
            <Tab>refund</Tab>
          </TabList>
        </Center>
        <TabPanels>
          <TabPanel>
            <Center>
              <Text fontSize="5xl" color="white">campaigns</Text>
            </Center>
            <Box p="1">
              <Stack spacing={2}>
                {listOfProjects()}
              </Stack>
            </Box>
          </TabPanel>
          <TabPanel>
            <Center>
              <Text fontSize="5xl" color="white">new!</Text>
            </Center>
              <Stack spacing={3} p="3" minWidth="350px">
                <Input
                  name="athletename"
                  type="text"
                  placeholder="athlete"
                  color="white"
                  value={state.athletename}
                  onChange={handleInputChange}
                />
                <Input
                  name="description"
                  type="text"
                  placeholder="description/social"
                  color="white"
                  value={state.description}
                  onChange={handleInputChange}
                />
                <Input
                  name="targetschool1"
                  type="text"
                  placeholder="target school 1"
                  color="white"
                  value={state.targetschool1}
                  onChange={handleInputChange}
                />
                <Input
                  name="targetschool2"
                  type="text"
                  placeholder="target school 2"
                  color="white"
                  value={state.targetschool2}
                  onChange={handleInputChange}
                />
                <Input
                  name="targetschool3"
                  type="text"
                  placeholder="target school 3"
                  color="white"
                  value={state.targetschool3}
                  onChange={handleInputChange}
                />
              </Stack>
              <Button isFullWidth colorScheme="blue" onClick={handleStartContract}>
                new contract
              </Button>
          </TabPanel>
          <TabPanel
            minWidth="350px"
          >
            <Center>
              <Text fontSize="5xl" color="white">fund!</Text>
            </Center>
              <Stack spacing={3} p="3">
                <Select
                  name="athleteSelect"
                  placeholder='select athlete'
                  color="white"
                  value={state.athleteSelect}
                  onChange={handleInputChange}
                  >
                  {listOfProjectsDonate_forDropdown()}
                </Select>
                <Select
                  name="schoolSelect"
                  placeholder='select school'
                  color="white"
                  value={state.schoolSelect}
                  onChange={handleInputChange}
                  >
                  <GetDetailsDonateSchool key={state.athleteSelect} address={state.athleteSelect} />
                </Select>
                <Input
                  name="value2add"
                  type="text"
                  placeholder="value in ONE"
                  color="white"
                  value={state.value2add}
                  onChange={handleInputChange}
                />
              </Stack>
              {/* <GetDetailsMin addrlist={campsStored_forDropdown} sendDataToParent2={sendDataToParent2} /> */}
              <Stack spacing={2}>
                <Center><Text fontSize="2xl">${donatevalUSD.toFixed(2)}</Text></Center>
                <Center>
                  <Button mx="2" id="20bux" display="inline-block" colorScheme="green" onClick={handleDonateBux}>
                    $20
                  </Button>
                  <Button mx="2" id="50bux" display="inline-block" colorScheme="green" onClick={handleDonateBux}>
                    $50
                  </Button>
                  <Button mx="2" id="100bux" display="inline-block" colorScheme="green" onClick={handleDonateBux}>
                    $100
                  </Button>
                </Center>
                <Button isFullWidth colorScheme="blue" onClick={handleDonateContract}>
                  donate!
                </Button>
              </Stack>
                <Center pt="3">
                  <Get247InfoCardJSON address={state.athleteSelect} />
                </Center>
          </TabPanel>
          <TabPanel
            minWidth="350px"
          >
          <Center>
            <Text fontSize="5xl" color="white">refund!</Text>
          </Center>
          <Stack spacing={3} p="3">
            <Select
              name="athleteSelectRefund"
              placeholder='select athlete'
              color="white"
              value={state.athleteSelectRefund}
              onChange={handleInputChange}
              >
              {listOfProjectsDonate_forDropdown()}
            </Select>
            <Select
              name="schoolSelectRefund"
              placeholder='select school'
              color="white"
              value={state.schoolSelectRefund}
              onChange={handleInputChange}
              >
              <GetDetailsDonateSchool key={state.athleteSelectRefund} address={state.athleteSelectRefund} />
            </Select>
          </Stack>
          <Button isFullWidth colorScheme="blue" onClick={handleRefundContract}>
            refund!
          </Button>
          </TabPanel>
        </TabPanels>
      </Tabs>
      </Box>
    </Flex>
  );
};

export default InfoContract;