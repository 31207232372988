import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Text } from "@chakra-ui/react";

import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { isMobile } from 'react-device-detect';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const Functionz = () => {

function overlayRamp() {
    if (isMobile) {
        new RampInstantSDK({
            hostAppName: 'nilFundMe',
            hostLogoUrl: 'https://nilfund.me/static/media/nilfundme%20logo%20final.be26c051.png',
            selectedCountryCode: 'US',
            defaultAsset: 'HARMONY_ONE',
            variant: 'mobile'
            }).show();
    }
    else {
        new RampInstantSDK({
            hostAppName: 'nilFundMe',
            hostLogoUrl: 'https://nilfund.me/static/media/nilfundme%20logo%20final.be26c051.png',
            selectedCountryCode: 'US',
            defaultAsset: 'HARMONY_ONE',
            }).show();
    }
}

function docsClick() {
	window.location.href = "https://docs.nilfund.me";
}

	return (
		<>
			<AccountComponent>
				<div onClick={docsClick}>
					<Text fontSize="l" color="black">Docs</Text>
				</div>
			</AccountComponent>
			<AccountComponent>
				<div onClick={overlayRamp}>
					<Text fontSize="l" color="black">Buy ONE!</Text>
				</div>
			</AccountComponent>
		</>
	);
};

const AccountComponent = styled.div`
	padding: 10px 20px;
	border-radius: 10px;
	background-color: white;
	opacity: 0.7;
	box-shadow: 1px 2px 4px 4px rgba(0, 0, 0, 0.25);
	color: black;
	margin-left: 10px;
	transition: opacity 0.3s ease, box-shadow 0.25s ease-in-out;

	&:hover {
		opacity: 1;
		box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.2);
		cursor: pointer;
	}
`;

export default Functionz;