// Count.tsx
import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  GridItem,
  Center,
  IconButton,
} from "@chakra-ui/react";
import { ExternalLinkIcon, AddIcon } from '@chakra-ui/icons'
import { StatusIndicator } from 'evergreen-ui'
import { Harmony, HarmonyExtension } from '@harmony-js/core';
import { HarmonyAbstractConnector } from '@harmony-react/abstract-connector';
import { Contract as HarmonyContract } from '@harmony-js/contract';
import { useHarmony } from '../context/harmonyContext';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { getExtension } from '../helpers/harmonyHelpers';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';
import ChildContract from '../contracts/ChildContract.json';
import { Contract } from '@harmony-js/contract';
import { fromWei, hexToNumber, Units } from '@harmony-js/utils';
import { useCoingeckoPrice } from '@usedapp/coingecko'
// import GetStatus from './GetStatus'


export default function GetDetails(props: any) {
  // const details = useGetProjDetails(["0xD915B03B2cCDD1119b39AFaF0e33DacF8F0777FE", "0xa1BaE546D42D0e79528286F535d508cbc4211445"]);
  const { hmy } = useHarmony();
  const { account, connector, library } = useWeb3React();
  const address = props.address;
  const harmonyPrice = useCoingeckoPrice('harmony', 'usd');
  let status_school1 = "";
  let status_school2 = "";
  let status_school3 = "";
  // console.log('address: ', address);
  const [state, setState] = useState({
    athletename: "",
    description: "",
    targetschool1: "",
    targetschool2: "",
    targetschool3: "",
    totalraised: "",
    amtRaized1: "",
    amtRaized2: "",
    amtRaized3: "",
  });

  const [statusState, setstatusState] = useState({
    targetschool1status: "disabled",
    targetschool2status: "disabled",
    targetschool3status: "disabled",
  });

  const createChildContract = (hmy: Harmony | HarmonyExtension): HarmonyContract => {
  	return hmy.contracts.createContract(ChildContract.contracts.Child.abi, address);
  };

  const getChildContractFromConnector = async (
  	connector: AbstractConnector,
  	web3Provider: any,
  ): Promise<any> => {
  	const harmonyConnector = connector as HarmonyAbstractConnector;
  	if (harmonyConnector.windowKey) {
  		const extensionWallet: any = window[harmonyConnector.windowKey];
  		const hmyExtension = getExtension(extensionWallet);
  		return new Promise(resolve => resolve(createChildContract(hmyExtension)));
  	}
  	// Connector is injected connector
  	await web3Provider.provider.request({ method: 'eth_requestAccounts' });
  	const web3 = new Web3(web3Provider.provider);
  	const web3Contract = new web3.eth.Contract(
  		ChildContract.contracts.Child.abi as any,
  		address,
  	);
  	return web3Contract;
  };

  const [childcontract, setChildContract] = useState<Contract | null>(createChildContract(hmy));

  // const [pricedata,setpriceData]=usepriceState([]);

  const getCampDets = async () => {
    if (childcontract) {
      try {
        const details = await childcontract.methods.getDetails().call();
        // console.log('details: ', details);
        setState({
          ...state,
          athletename: details.projectAthlete,
          description: details.projectDesc,
          targetschool1: details.projectSchool1,
          targetschool2: details.projectSchool2,
          targetschool3: details.projectSchool3,
          totalraised: details.totalInContract,
          amtRaized1: details.amtRaized1,
          amtRaized2: details.amtRaized2,
          amtRaized3: details.amtRaized3,
        });
        // console.log('proj athlete: ', state.athletename);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getStatusSchool = async () => {
    if (childcontract) {
      try {
        const status1 = await childcontract.methods.StateInt(state.targetschool1).call();
        if ((status1.words).toString() == 5){
          status_school1 = "success";
        }
        else if ((status1.words).toString() == 1){
          status_school1 = "danger";
        }
        else if ((status1.words).toString() == 2){
          status_school1 = "warning";
        }
        else {
          status_school1 = "disabled";
        }
        // console.log('target school1 | status: ' + state.targetschool1 + ' | ' + (status1.words).toString());
        // console.log('status: ', (status1.words).toString());
      } catch (error) {
        console.error(error);
      }
      try {
        const status2 = await childcontract.methods.StateInt(state.targetschool2).call();
        if ((status2.words).toString() == 5){
          status_school2 = "success";
        }
        else if ((status2.words).toString() == 1){
          status_school2 = "danger";
        }
        else if ((status2.words).toString() == 2){
          status_school2 = "warning";
        }
        else {
          status_school2 = "disabled";
        }
        // console.log('target school2 | status: ' + state.targetschool2 + ' | ' + (status2.words).toString());
      } catch (error) {
        console.error(error);
      }
      try {
        const status3 = await childcontract.methods.StateInt(state.targetschool3).call();
        if ((status3.words).toString() == 5){
          status_school3 = "success";
        }
        else if ((status3.words).toString() == 1){
          status_school3 = "danger";
        }
        else if ((status3.words).toString() == 2){
          status_school3 = "warning";
        }
        else {
          status_school3 = "disabled";
        }
        // console.log('target school3 | status: ' + state.targetschool3 + ' | ' + (status3.words).toString());
      } catch (error) {
        console.error(error);
      }
      // console.log('status: ' + status_school1 + ' ' + status_school2 + ' ' + status_school3);
      setstatusState({
        ...statusState,
        targetschool1status: status_school1,
        targetschool2status: status_school2,
        targetschool3status: status_school3,
      });
    }
  };

  useEffect(() => {
    getCampDets();
  }, []);

  useEffect(() => {
    getStatusSchool();
  }, [state.targetschool1, state.targetschool2, state.targetschool3]);

  useEffect(() => {
		if (connector) {
			(async () => {
				const childcontract = await getChildContractFromConnector(connector, library);
				setChildContract(childcontract);
			})();
		}
	}, [connector, setChildContract]);

  const amtRaized1 = (state.amtRaized1 && harmonyPrice) ? parseFloat(fromWei(state.amtRaized1, Units.one))*parseFloat(harmonyPrice) : 0.00;
  const amtRaized2 = (state.amtRaized2 && harmonyPrice) ? parseFloat(fromWei(state.amtRaized2, Units.one))*parseFloat(harmonyPrice) : 0.00;
  const amtRaized3 = (state.amtRaized3 && harmonyPrice) ? parseFloat(fromWei(state.amtRaized3, Units.one))*parseFloat(harmonyPrice) : 0.00;
  const contractVal = (state.totalraised && harmonyPrice) ? parseFloat(fromWei(state.totalraised, Units.one))*parseFloat(harmonyPrice) : 0.00;

  function handleExplorerLink() {
    // window.location.href = 'https://explorer.harmony.one/address/' + props.address;
    window.open(
      'https://explorer.harmony.one/address/' + props.address,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  return (
    <>
      <GridItem
        rowSpan={1}
        colSpan={3}
        color="white"
        fontSize="4xl"
      >
        <Center>{state.athletename}</Center>
      </GridItem>
      <GridItem
        rowSpan={1}
        colSpan={1}
        color="white"
        fontSize="sm"
      >
          <Center><StatusIndicator dotSize={30} color={statusState.targetschool1status}></StatusIndicator></Center>
      </GridItem>
      <GridItem
        rowSpan={1}
        colSpan={1}
        color="white"
        fontSize="sm"
      >
        <Center><StatusIndicator dotSize={30} color={statusState.targetschool2status}></StatusIndicator></Center>
      </GridItem>
      <GridItem
        rowSpan={1}
        colSpan={1}
        color="white"
        fontSize="sm"
      >
        <Center><StatusIndicator dotSize={30} color={statusState.targetschool3status}></StatusIndicator></Center>
      </GridItem>
      <GridItem
        rowSpan={1}
        colSpan={1}
        color="white"
      >
        <Center>{state.targetschool1}</Center>
      </GridItem>
      <GridItem
        rowSpan={1}
        colSpan={1}
        color="white"
      >
        <Center>{state.targetschool2}</Center>
      </GridItem>
      <GridItem
        rowSpan={1}
        colSpan={1}
        color="white"
      >
        <Center>{state.targetschool3}</Center>
      </GridItem>
      <GridItem
        rowSpan={1}
        colSpan={1}
        color="white"
      >
        <Center>${amtRaized1.toFixed(2)} USD</Center>
      </GridItem>
      <GridItem
        rowSpan={1}
        colSpan={1}
        color="white"
      >
        <Center>${amtRaized2.toFixed(2)} USD</Center>
      </GridItem>
      <GridItem
        rowSpan={1}
        colSpan={1}
        color="white"
      >
        <Center>${amtRaized3.toFixed(2)} USD</Center>
      </GridItem>
      <GridItem
        color="white"
        rowSpan={1}
        colSpan={3}
        fontSize="2xl"
      >
        <Center>
        ${contractVal.toFixed(2)} USD
        <IconButton ml="10px" mb="3px" aria-label='Blockchain Explorer' variant='outline' colorScheme='white' onClick={handleExplorerLink} icon={<ExternalLinkIcon />} />
        </Center>
      </GridItem>
    </>
  );
}
