import { Harmony, HarmonyExtension } from '@harmony-js/core';
import { HarmonyAbstractConnector } from '@harmony-react/abstract-connector';
import { Contract as HarmonyContract } from '@harmony-js/contract';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { getExtension } from '../helpers/harmonyHelpers';
import Web3 from 'web3';

import ParentContract from '../contracts/ParentContract.json';
import ChildContract from '../contracts/ChildContract.json';

export const createParentContract = (hmy: Harmony | HarmonyExtension): HarmonyContract => {
	return hmy.contracts.createContract(ParentContract.contracts.Parent.abi, ParentContract.contracts.Parent.address);
};

export const createChildContract = (contractAddr: string, hmy: Harmony | HarmonyExtension): HarmonyContract => {
	return hmy.contracts.createContract(ChildContract.contracts.Child.abi, contractAddr);
};

export const getParentContractFromConnector = async (
	connector: AbstractConnector,
	web3Provider: any,
): Promise<any> => {
	const harmonyConnector = connector as HarmonyAbstractConnector;
	if (harmonyConnector.windowKey) {
		const extensionWallet: any = window[harmonyConnector.windowKey];
		const hmyExtension = getExtension(extensionWallet);
		return new Promise(resolve => resolve(createParentContract(hmyExtension)));
	}
	// Connector is injected connector
	await web3Provider.provider.request({ method: 'eth_requestAccounts' });
	const web3 = new Web3(web3Provider.provider);
	const web3Contract = new web3.eth.Contract(
		ParentContract.contracts.Parent.abi as any,
		ParentContract.contracts.Parent.address,
	);
	return web3Contract;
};

export const getChildContractFromConnector = async (
	connector: AbstractConnector,
	web3Provider: any,
	addr2query: string,
): Promise<any> => {
	const harmonyConnector = connector as HarmonyAbstractConnector;
	if (harmonyConnector.windowKey) {
		const extensionWallet: any = window[harmonyConnector.windowKey];
		const hmyExtension = getExtension(extensionWallet);
		return new Promise(resolve => resolve(createChildContract(addr2query, hmyExtension)));
	}
	// Connector is injected connector
	await web3Provider.provider.request({ method: 'eth_requestAccounts' });
	const web3 = new Web3(web3Provider.provider);
	const web3Contract = new web3.eth.Contract(
		ChildContract.contracts.Child.abi as any,
		addr2query,
	);
	return web3Contract;
};
