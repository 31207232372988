import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';

import { useHarmony } from '../context/harmonyContext';

const Balance = () => {
	const { account, chainId } = useWeb3React();
	const { balance, fetchBalance, resetBalance } = useHarmony();
	useEffect(() => {
		if (account) {
			fetchBalance(account);
		} else {
			resetBalance();
		}
	}, [account, chainId, fetchBalance]);

	if (!balance) return null;

	return (
		<BalanceComponent>
			<b>{parseFloat(balance).toFixed(3)}</b> <span>ONE</span>
		</BalanceComponent>
	);
};

const BalanceComponent = styled.div`
	padding: 10px 20px;
	border-radius: 10px;
	background-color: white;
	opacity: 0.7;
	box-shadow: 1px 2px 4px 4px rgba(0, 0, 0, 0.25);
	color: black;
	margin-left: 10px;
	margin-bottom: 10px;
	transition: opacity 0.3s ease, box-shadow 0.25s ease-in-out;

	&:hover {
		opacity: 1;
		box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.2);
		cursor: pointer;
	}
`;

export default Balance;
