// Count.tsx
import { useState, useEffect } from "react";
import { Harmony, HarmonyExtension } from '@harmony-js/core';
import { HarmonyAbstractConnector } from '@harmony-react/abstract-connector';
import { Contract as HarmonyContract } from '@harmony-js/contract';
import { useHarmony } from '../context/harmonyContext';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { getExtension } from '../helpers/harmonyHelpers';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';
import ChildContract from '../contracts/ChildContract.json';
import { Contract } from '@harmony-js/contract';
import { fromWei, hexToNumber, Units } from '@harmony-js/utils';


export default function GetDetailsDonateSchool(props: any) {
  // const details = useGetProjDetails(["0xD915B03B2cCDD1119b39AFaF0e33DacF8F0777FE", "0xa1BaE546D42D0e79528286F535d508cbc4211445"]);
  const { hmy } = useHarmony();
  const { account, connector, library } = useWeb3React();
  const address = props.address;
  // console.log('address: ', address);
  const [state, setState] = useState({
    targetschool1: "",
    targetschool2: "",
    targetschool3: "",
  });

  const createChildContract = (hmy: Harmony | HarmonyExtension): HarmonyContract => {
  	return hmy.contracts.createContract(ChildContract.contracts.Child.abi, address);
  };

  const getChildContractFromConnector = async (
  	connector: AbstractConnector,
  	web3Provider: any,
  ): Promise<any> => {
  	const harmonyConnector = connector as HarmonyAbstractConnector;
  	if (harmonyConnector.windowKey) {
  		const extensionWallet: any = window[harmonyConnector.windowKey];
  		const hmyExtension = getExtension(extensionWallet);
  		return new Promise(resolve => resolve(createChildContract(hmyExtension)));
  	}
  	// Connector is injected connector
  	await web3Provider.provider.request({ method: 'eth_requestAccounts' });
  	const web3 = new Web3(web3Provider.provider);
  	const web3Contract = new web3.eth.Contract(
  		ChildContract.contracts.Child.abi as any,
  		address,
  	);
  	return web3Contract;
  };

  const [childcontract, setChildContract] = useState<Contract | null>(createChildContract(hmy));

  const getCampDets = async () => {
    if (childcontract) {
      try {
        const details = await childcontract.methods.getDetails().call();
        console.log('details: ', details);
        setState({
          ...state,
          targetschool1: details.projectSchool1,
          targetschool2: details.projectSchool2,
          targetschool3: details.projectSchool3,
        });
        // console.log('proj athlete: ', state.athletename);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
		getCampDets();
	}, []);

  useEffect(() => {
		if (connector) {
			(async () => {
				const childcontract = await getChildContractFromConnector(connector, library);
				setChildContract(childcontract);
			})();
		}
	}, [connector, setChildContract]);

  return (
    <>
    <option value={state.targetschool1}>{state.targetschool1}</option>
    <option value={state.targetschool2}>{state.targetschool2}</option>
    <option value={state.targetschool3}>{state.targetschool3}</option>
    </>
  );
}
