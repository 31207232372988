import React from 'react';
import { ChakraProvider, useDisclosure, Text, Box, Center, Flex } from "@chakra-ui/react";
import theme from "./theme";
import Layout from "./components/Layout";
import { ToastContainer } from 'react-toastify';
import Account from './components/Account';
import Balance from './components/Balance';
import Functionz from './components/Functionz'
import InfoContract from './components/InfoContract';

import Logo from './img/nilfundme logo final.png';
import "@fontsource/inter";

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
	return (
		<ChakraProvider theme={theme}>
      <Layout>
				<img src={Logo} alt="nilfundme logo" />
				<Flex>
					<Balance />
					<Account />
				</Flex>
				<Flex>
					<Functionz />
				</Flex>
				<Box>
					<InfoContract />
				</Box>
				<ToastContainer
					position="bottom-right"
					newestOnTop={false}
					pauseOnFocusLoss={false}
					pauseOnHover={false}
					rtl={false}
				/>
			</Layout>
		</ChakraProvider>
	);
};

export default App;
