import React,{useState,useEffect} from 'react';

export default function Get247InfoCardJSON(props: any) {
  const projAddr = props.address;
  // console.log('projAddr passed via prop: ' + projAddr);
  const [data,setData]=useState([]);
  const getData=()=>{
    fetch('data.json'
    ,{
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson);
        setData(myJson)
      });
  }
  useEffect(()=>{
    getData()
  },[])
  return (
    <>
     {
       data && data.length>0 && data.map((item: any)=> (item.projAddr == projAddr) ? <iframe height="380" width="310" frameBorder="1" src={item.bioLink}></iframe> : '')
     }
     </>
  );
}
